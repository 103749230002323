import React, { useState, useEffect } from 'react';
import { Container, Box, Input, Chip, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const HomeComponent = () => {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(db, 'clients'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clientsData = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setClients(clientsData);
    });

    return () => unsubscribe();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredClients = clients.filter(client =>
    client.clientName.toLowerCase().includes(search.toLowerCase())
  );

  const paginatedClients = filteredClients.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const newCustomers = clients.filter(client => client.status === 'new').length;
  const inProgressCustomers = clients.filter(client => client.status === 'in progress').length;
  const completedCustomers = clients.filter(client => client.status === 'completed').length;
  const totalCompletedAmount = clients.filter(client => client.status === 'completed').reduce((sum, client) => sum + parseFloat(client.price || 0), 0);

  const getStatusChip = (status) => {
    let color = 'default';
    if (status === 'new') {
      color = 'primary';
    } else if (status === 'in progress') {
      color = 'warning';
    } else if (status === 'completed') {
      color = 'success';
    }
    return <Chip label={status} color={color} />;
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Card sx={{ minWidth: 275, backgroundColor: 'blue', color: 'white' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              New Customers
            </Typography>
            <Typography variant="h6" component="div">
              {newCustomers}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, backgroundColor: 'orange', color: 'white' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              In Progress
            </Typography>
            <Typography variant="h6" component="div">
              {inProgressCustomers}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, backgroundColor: 'green', color: 'white' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Completed
            </Typography>
            <Typography variant="h6" component="div">
              RM {totalCompletedAmount.toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <div style={{display: "flex", justifyContent: "space-between", height: "40px", marginTop: "50px"}}>
        <Input
          value={search}
          onChange={handleSearch}
          placeholder='Search'
          disableUnderline
          sx={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '300px',
            '&:before, &:after': {
              display: 'none',
            },
          }}
        />
      </div>

      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedClients.map((client) => (
              <TableRow key={client.id} hover onClick={() => navigate(`/view-document/${client.id}`)} style={{ cursor: 'pointer' }}>
                <TableCell>{client.clientName}</TableCell>
                <TableCell>{getStatusChip(client.status)}</TableCell>
                <TableCell>{client.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredClients.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default HomeComponent;

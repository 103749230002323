import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import DocumentsPage from './pages/DocumentsPage';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import Layouts from './components/Layouts';
import UsersPage from './pages/UsersPage';
import EditDocumentPage from './pages/EditDocumentPage';
import ViewDocumentPage from './pages/ViewDocumentPage';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute>
                <Layouts>
                  <HomePage />
                </Layouts>
              </PrivateRoute>} 
            />
          <Route path="/clients" element={
              <PrivateRoute>
                <Layouts>
                  <DocumentsPage />
                </Layouts>
              </PrivateRoute>} 
            />
          <Route path="/edit-document/:id" element={
              <PrivateRoute>
                <Layouts>
                  <EditDocumentPage />
                </Layouts>
              </PrivateRoute>} 
            />
          <Route path="/view-document/:id" element={
              <PrivateRoute>
                <Layouts>
                  <ViewDocumentPage />
                </Layouts>
              </PrivateRoute>} 
            />
          <Route path="/users" element={
              <PrivateRoute>
                <Layouts>
                  <UsersPage />
                </Layouts>
              </PrivateRoute>} 
            />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import {
  Container, Button, Modal, Box, TextField, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Input, Chip, Pagination
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, onSnapshot, deleteDoc, doc } from 'firebase/firestore';

const DocumentComponent = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [documents, setDocuments] = useState([]);
  const [newDocument, setNewDocument] = useState({
    clientName: '', documents: [], clientEmail: '', clientContact: '', price: '', remarks: '',
    createdAt: new Date(), updatedAt: new Date(), createdBy: 'currentUser', updatedBy: 'currentUser', status: 'new'
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'clients'), (snapshot) => {
      const docs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setDocuments(docs);
    });

    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirmationOpen = (id) => {
    setSelectedDocumentId(id);
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => setConfirmationOpen(false);
  const handleSearch = (e) => setSearch(e.target.value);

  const handleAddDocument = async () => {
    try {
      await addDoc(collection(db, 'clients'), {
        ...newDocument,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      setNewDocument({
        clientName: '', documents: [], clientEmail: '', clientContact: '', price: '', remarks: '',
        createdAt: new Date(), updatedAt: new Date(), createdBy: 'currentUser', updatedBy: 'currentUser', status: 'new'
      });
      handleClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleEditDocument = (id) => {
    navigate(`/edit-document/${id}`);
  };

  const handleDeleteDocument = async () => {
    try {
      await deleteDoc(doc(db, 'clients', selectedDocumentId));
      setDocuments(documents.filter(doc => doc.id !== selectedDocumentId));
      handleConfirmationClose();
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredDocuments = documents.filter(doc => doc.clientName.toLowerCase().includes(search.toLowerCase()));
  const paginatedDocuments = filteredDocuments.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const getStatusChip = (status) => {
    let color = 'default';
    if (status === 'new') {
      color = 'primary';
    } else if (status === 'in progress') {
      color = 'warning';
    } else if (status === 'completed') {
      color = 'success';
    }
    return <Chip label={status} color={color} />;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Client Management</Typography>
      <div style={{display: "flex", justifyContent: "space-between", height: "40px"}}>
        <Input
          value={search}
          onChange={handleSearch}
          placeholder='Search'
          disableUnderline
          sx={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '300px',
            '&:before, &:after': {
              display: 'none',
            },
          }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
          Add Client
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDocuments.map((doc) => (
              <TableRow key={doc.id} hover onClick={() => navigate(`/view-document/${doc.id}`)}>
                <TableCell>{doc.clientName}</TableCell>
                <TableCell>{doc.clientContact}</TableCell>
                <TableCell>{doc.clientEmail}</TableCell>
                <TableCell>{getStatusChip(doc.status)}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleEditDocument(doc.id); }}><EditIcon style={{color: 'black'}}/></IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleConfirmationOpen(doc.id); }}><DeleteIcon style={{color: 'red'}}/></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredDocuments.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 4, m: 'auto', mt: 8, width: 400, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom>Add New Document</Typography>
          <TextField
            label="Client Name"
            variant="outlined"
            name="clientName"
            value={newDocument.clientName}
            onChange={(e) => setNewDocument({ ...newDocument, clientName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client Email"
            variant="outlined"
            name="clientEmail"
            value={newDocument.clientEmail}
            onChange={(e) => setNewDocument({ ...newDocument, clientEmail: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client Contact"
            variant="outlined"
            name="clientContact"
            value={newDocument.clientContact}
            onChange={(e) => setNewDocument({ ...newDocument, clientContact: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            variant="outlined"
            name="price"
            value={newDocument.price}
            onChange={(e) => setNewDocument({ ...newDocument, price: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Remarks"
            variant="outlined"
            name="remarks"
            value={newDocument.remarks}
            onChange={(e) => setNewDocument({ ...newDocument, remarks: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddDocument} sx={{ mt: 2 }}>
            Add Client
          </Button>
        </Box>
      </Modal>

      <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 4, m: 'auto', mt: 8, width: 400, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom>Confirm Deletion</Typography>
          <Typography variant="body1">Are you sure you want to delete this document?</Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleConfirmationClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleDeleteDocument}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default DocumentComponent;

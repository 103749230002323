import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Typography, Snackbar, Alert, Input } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { onSnapshot, query, orderBy, collection, addDoc, serverTimestamp } from 'firebase/firestore';


const UserComponent = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ name: '', email: '' });
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const [userExist, setUserExists] = useState(false);

  useEffect(() => {
    const q = query(collection(db, 'users'), orderBy('name'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    });
    return unsubscribe;
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const handleChange = (e) => setNewUser({ ...newUser, [e.target.name]: e.target.value });
  const handleSearch = (e) => setSearch(e.target.value);

  const generatePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleAddUser = async () => {
    const password = generatePassword();
    const currentUser = auth.currentUser;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, password);
      const user = userCredential.user;
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: newUser.name,
        email: newUser.email,
        password: password,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: currentUser ? currentUser.uid : 'system',
        updatedBy: currentUser ? currentUser.uid : 'system'
      });
      setNewUser({ name: '', email: '' });
      handleClose();
      setSnackbarMessage('User successfully created!');
      setSnackbarOpen(true);
    } catch (error) {
        setUserExists(true);
        console.error('Error adding user:', error);
        setSnackbarMessage('Email already exists. Please try again.');
        setSnackbarOpen(true);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredUsers = users.filter(user => user.name.toLowerCase().includes(search.toLowerCase()));
  const paginatedUsers = filteredUsers.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>User Management</Typography>
      <div style={{display: "flex", justifyContent: "space-between", height: "40px"}}>
        <Input
            value={search}
            onChange={handleSearch}
            placeholder='Search'
            disableUnderline
            sx={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                width: '300px'
            }}
            />
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
            Add User
            </Button>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 4, m: 'auto', mt: 8, width: 400, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom>Add New User</Typography>
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={newUser.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={newUser.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddUser} sx={{ mt: 2 }}>
            Add User
          </Button>
        </Box>
      </Modal>
      <TableContainer>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.createdAt.toDate().toISOString().split('T')[0]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredUsers.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Alert onClose={handleSnackbarClose} severity={userExist ? "error" : "success"} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserComponent;

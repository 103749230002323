import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Container, TextField, Button, Box, Typography, IconButton, CircularProgress, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const EditDocumentPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchDocument = async () => {
      const docRef = doc(db, 'clients', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDocument({ id: docSnap.id, ...docSnap.data() });
        setLoading(false);
      } else {
        console.error('No such document!');
        setLoading(false);
      }
    };

    fetchDocument();
  }, [id]);

  const handleChange = (e) => {
    setDocument({ ...document, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const docRef = doc(db, 'clients', id);
    await updateDoc(docRef, { ...document });
    navigate('/clients');
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;
    setUploading(true);
    const fileRef = ref(storage, `documents/${file.name}`);
    await uploadBytes(fileRef, file);
    const fileUrl = await getDownloadURL(fileRef);
    setDocument(prevState => ({
      ...prevState,
      documents: [...prevState.documents, fileUrl]
    }));
    setFile(null);
    setUploading(false);
  };

  const handleRemoveDocument = (url) => {
    setDocument(prevState => ({
      ...prevState,
      documents: prevState.documents.filter(doc => doc !== url)
    }));
  };

  const extractFileName = (url) => {
    return url.split('/').pop().split('#')[0].split('?')[0];
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Edit Document</Typography>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
      <Box display="flex" flexDirection="column">
        <TextField
          label="Client Name"
          variant="outlined"
          name="clientName"
          value={document.clientName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Client Email"
          variant="outlined"
          name="clientEmail"
          value={document.clientEmail}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Client Contact"
          variant="outlined"
          name="clientContact"
          value={document.clientContact}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Price"
          variant="outlined"
          name="price"
          value={document.price}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Remarks"
          variant="outlined"
          name="remarks"
          value={document.remarks}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            name="status"
            value={document.status}
            onChange={handleChange}
          >
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="in progress">In Progress</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
        <Box mt={2}>
          <Typography variant="h6">Upload Documents</Typography>
          <input type="file" onChange={handleFileChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            disabled={!file || uploading}
            sx={{ mt: 1 }}
          >
            {uploading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">Uploaded Documents</Typography>
          {document.documents && document.documents.map((docUrl, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <Link component={RouterLink} to={docUrl} target="_blank" rel="noopener noreferrer">
                {extractFileName(docUrl)}
              </Link>
              <IconButton onClick={() => handleRemoveDocument(docUrl)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default EditDocumentPage;

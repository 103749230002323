import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Box, Typography, Link, CircularProgress } from '@mui/material';

const ViewDocumentPage = () => {
  const { id } = useParams();
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocument = async () => {
      const docRef = doc(db, 'clients', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDocument({ id: docSnap.id, ...docSnap.data() });
        setLoading(false);
      } else {
        console.error('No such document!');
        setLoading(false);
      }
    };

    fetchDocument();
  }, [id]);

  const extractFileName = (url) => {
    return url.split('/').pop().split('#')[0].split('?')[0];
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>View Document</Typography>
      <Box display="flex" flexDirection="column" mt={2}>
        <Typography variant="h6">Client Name</Typography>
        <Typography>{document.clientName}</Typography>
        <Typography variant="h6" mt={2}>Client Email</Typography>
        <Typography>{document.clientEmail}</Typography>
        <Typography variant="h6" mt={2}>Client Contact</Typography>
        <Typography>{document.clientContact}</Typography>
        <Typography variant="h6" mt={2}>Price</Typography>
        <Typography>RM {document.price}</Typography>
        <Typography variant="h6" mt={2}>Remarks</Typography>
        <Typography>{document.remarks}</Typography>
        <Typography variant="h6" mt={2}>Uploaded Documents</Typography>
        {document.documents && document.documents.map((docUrl, index) => (
          <Box key={index} display="flex" alignItems="center" mt={1}>
            <Link href={docUrl} target="_blank" rel="noopener noreferrer">
              {extractFileName(docUrl)}
            </Link>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default ViewDocumentPage;
